import { pipe } from 'fp-ts/lib/function'

import { D } from '@/lib/fp'
import { http } from '@/lib/http'

export const displayTypeDecoder = D.union(
  D.literal('number'),
  D.literal('boost_percentage'),
  D.literal('boost_number'),
  D.literal('date'),
)
export type DisplayType = D.TypeOf<typeof displayTypeDecoder>

export const attributeDecoder = pipe(
  D.struct({
    trait_type: D.string,
    value: D.union(D.number, D.string),
  }),
  D.intersect(
    D.partial({
      display_type: displayTypeDecoder,
      max_value: D.number,
    }),
  ),
)
export type Attribute = D.TypeOf<typeof attributeDecoder>

export const spriteAnimationConfigDecoder = pipe(
  D.struct({
    frame_width: D.number,
    frame_height: D.number,
    frames: D.array(D.struct({ x: D.number, y: D.number })),
    framerate: D.number,
  }),
  D.intersect(
    D.partial({
      transform: D.string,
    }),
  ),
)
export type SpriteAnimationConfig = D.TypeOf<
  typeof spriteAnimationConfigDecoder
>

export const unimetaConfigDecoder = D.partial({
  foreground_image: D.string, // a cover over the image
  background_image: D.string, // to be put inside background-image
  background_color: D.string,
  pixelated: D.boolean, // whether to scale using `image-rendering: pixelated;`
  transform: D.string, // zoom in picture
  ratio: D.number, // ratio of the image
  sprite_animation: spriteAnimationConfigDecoder,
})
export type UnimetaConfig = D.TypeOf<typeof unimetaConfigDecoder>

export const collectionDisplayOptionsDecoder = D.partial({
  is_video: D.boolean,
  is_iframe: D.boolean,
  pixelated: D.boolean,
  use_gif: D.boolean,
})
export type CollectionDisplayOptions = D.TypeOf<
  typeof collectionDisplayOptionsDecoder
>

export const openseaMetadataDecoder = D.partial({
  name: D.string,
  description: D.string,
  external_url: D.string,
  image: D.string,
  image_data: D.string,
  background_color: D.string,
  animation_url: D.string,
  youtube_url: D.string,
  attributes: D.array(attributeDecoder),
  _config: unimetaConfigDecoder,
})
export type OpenSeaMetadata = D.TypeOf<typeof openseaMetadataDecoder>

export type GetMetaResponse = {
  token_uri: string | null
  metadata_content: OpenSeaMetadata | null
}

export const getMetaPayloadDecoder = D.struct({
  network_id: D.number,
  contract: D.string,
  token_id: D.string,
})
export type GetMetaPayload = D.TypeOf<typeof getMetaPayloadDecoder>
export const getMeta = async (payload: GetMetaPayload) => {
  const resp = await http.post<GetMetaResponse>(
    `${process.env.UNIMETA_ENDPOINT}/api/meta`,
    payload,
    {
      headers: {
        'Content-Type': 'application/json',
        'x-tofu-api-key': 'a5f82a4a-63ae-43ad-a813-ce7fc673f7e8',
      },
    },
  )
  const json = resp.data
  if (json.metadata_content) return json.metadata_content
  else throw new Error('No data found')
}

export const batchGetMetaPayloadDecoder = D.array(
  pipe(
    D.struct({
      network_id: D.number,
      contract: D.string,
      token_id: D.string,
    }),
    D.intersect(
      D.partial({
        token_standard: D.literal('erc1155'),
      }),
    ),
  ),
)
export type BatchGetMetaPayload = D.TypeOf<typeof batchGetMetaPayloadDecoder>
export const batchGetMeta = async (payload: BatchGetMetaPayload) => {
  const resp = await http.post<GetMetaResponse>(
    `${process.env.UNIMETA_ENDPOINT}/api/batch`,
    payload,
    {
      headers: {
        'Content-Type': 'application/json',
        'x-tofu-api-key': 'a5f82a4a-63ae-43ad-a813-ce7fc673f7e8',
        'x-no-fetch': 'true',
      },
      timeout: 15_000,
    },
  )
  return resp.data
}
export const buildMetaKey = ({
  network_id,
  contract,
  token_id,
}: {
  network_id: number
  contract: string
  token_id: string
}) => [network_id, contract, token_id].join('/')
